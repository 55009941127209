import React from 'react'
import { useTranslation, I18nContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

const Sentinel = () => {
  const { i18n } = React.useContext(I18nContext)
  const { t } = useTranslation()

  return (
      <Layout>
          <SEO title="Sentinel" language={i18n.language} />
          <div className="sentinel-page">
              <div className="sentinel-page-header">
                  <div className="sentinel-page-header-logo">
                      <div className="sentinel-page-header-logo-img" />
                  </div>
                  <div className="sentinel-page-header-vision">
                      <p>{t('sentinel-vision').toUpperCase()}</p>
                  </div>
              </div>
              <div className="sentinel-page-overview">
                  <div className="sentinel-page-overview-text">
                      <p>{t('sentinel-overview-1')}</p>
                  </div>
              </div>
              <div className="sentinel-page-info">
                  <div className="sentinel-page-info-title">
                      <p>{t('sentinel-info-0-title')}</p>
                  </div>
                  <div className="sentinel-page-info-text">
                      <p>{t('sentinel-info-0-text')}</p>
                  </div>
                  <div className="sentinel-page-info-title">
                      <p>{t('sentinel-info-1-title')}</p>
                  </div>
                  <div className="sentinel-page-info-text">
                      <p>{t('sentinel-info-1-text')}</p>
                  </div>
                  <div className="sentinel-page-info-title">
                      <p>{t('sentinel-info-2-title')}</p>
                  </div>
                  <div className="sentinel-page-info-text">
                      <p>{t('sentinel-info-2-text')}</p>
                  </div>
                  <div className="sentinel-page-info-title">
                      <p>{t('sentinel-info-3-title')}</p>
                  </div>
                  <div className="sentinel-page-info-text">
                      <p>{t('sentinel-info-3-text')}</p>
                  </div>
                  <div className="sentinel-page-info-title">
                      <p>{t('sentinel-info-4-title')}</p>
                  </div>
                  <div className="sentinel-page-info-text">
                      <p>{t('sentinel-info-4-text')}</p>
                  </div>
              </div>
              <div className="sentinel-page-divider">
                  <p>{t('divider-examples')}</p>
              </div>
              <div className="sentinel-page-examples">
                  <div className="sentinel-page-examples-pic sentinel-page-examples-forklift" />
                  <div className="sentinel-page-examples-pic sentinel-page-examples-human" />
                  <div className="sentinel-page-examples-pic sentinel-page-examples-starling" />
              </div>
          </div>
      </Layout>
  )
}

export default Sentinel

export const query = graphql`
    query($language: String!) {
      locales: allLocale(
        filter: {
          ns: { in: ["common", "header", "products", "footer"] }
          language: { eq: $language }
        }
      ) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
  `
